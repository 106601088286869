import Vue from 'vue'
import CommonMethods from '@/utils/methods'
Vue.prototype.$methods = CommonMethods


Vue.mixin({
    methods: {
        gotoForm () {
            this.$router.push({ name: 'Form' })
        },
        handleManual(){
            let width = 1000
            let height = 700
            const url = 'https://tb.53kf.com/code/client/996f6b612d54d57fa67d3a77c680b4a40/1'
            //window.open(url,name,specs) name为空
            // const url = 'https://tb.53kf.com/code/client/996f6b612d54d57fa67d3a77c680b4a40/1'
            window.open(url,'',`width=${width},height=${height}`)
        },
    }
})

//全局基础数据选项
import globalDataOptions from '@/assets/data/render.uidata'
import router from '../router'
Vue.prototype.$DataOptions = globalDataOptions

export default ({ app, $axios, store, redirect }, inject) => {
    inject('DataOptions', globalDataOptions)
    inject('methods', CommonMethods)
}