
  import _ from 'lodash'
  export default {
    props: {
      text: {
        type: String,
        default: () => '立即咨询'
      },
      type: {
        type: String,
        default: () => 'digitalIntel'
      },
      block: {
        type: Boolean,
        default: () => false
      },
      fontSize: {
        type: String,
        default: () => 'medium'
      },
      size: {
        type: String,
        default: () => 'medium'
      },
      fontColor: {
        type: String,
        default: () => '#fff'
      },
      fontHoverColor: {
        type: String,
        default: () => '#fff'
      },
      hoverChange: {
        type: Boolean,
        default: () => false
      },
      btnColor: {
        type: String,
        default: () => 'deepRed'
      },
      hoverColor: {
        type: String,
        default: () => ''
      },
      borderColor: {
        type: String,
        default: () => ''
      }
    },
    data() {
      return {
        btnStylePostion: {
          '--x': '0px', 
          '--y': '0px', 
        },
        colorStore: {
            blue: {
                background: 'linear-gradient(180deg, #1763FF 0%, #39C3FB 100%)',
                mouse: 'radial-gradient(circle closest-side,rgba(0,255,248,.37647),transparent)'
            },
            orange: {
                background: 'linear-gradient(90deg, #FF6B03 0%, #FF8A1F 100%)',
                mouse: 'radial-gradient(circle closest-side,rgba(255, 229, 0, 0.37),transparent)'
            },
            red: {
                background: '#E1251B',
                mouse: 'radial-gradient(circle closest-side,rgba(255,169,174,.37647),transparent)',
            },
            deepRed: {
                background: '#E1251B',
                mouse: 'radial-gradient(circle closest-side,rgba(255,169,174,.37647),transparent)'
            },
            lightRed: {
                background: '#F00',
                mouse: 'radial-gradient(circle closest-side,rgba(255,169,174,.37647),transparent)'
            }
        }
      }
    },
    computed: {
        btnStyle () {
            const bgColor =  this.colorStore[this.btnColor] || this.btnColor
            const hoverColor =  this.colorStore[this.hoverColor || this.btnColor] || this.hoverColor
            return {
                ...this.btnStylePostion,
                '--bgimage': bgColor?.background || bgColor,
                '--mouse': bgColor?.mouse || '',
                '--bgimage-hover': hoverColor?.background || hoverColor,
                '--mouse-hover': hoverColor?.mouse || '',
                '--border-color': this.borderColor,
            }
        },
        fontStyle () {
            return {
                '--font-color': this.fontColor,
                '--font-hover-color': this.fontHoverColor,
            }
        }
    },
    methods: {
      updateBtnBackground:async function (event) {
        this.btnStylePostion['--x'] = event.offsetX + 'px';
        this.btnStylePostion['--y'] = event.offsetY + 'px';
      },
      handleClick (event) {
        this.$emit('click', event)
      }
    }
  }
  