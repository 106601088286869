
export default {
  props: {
    value: {
      type: String,
      default: () => ''
    },
    phone:{
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
       // 验证码倒计时计数器
      time: 0,

    }
  },
  computed: {
    // 验证码文字样式
    codeTextStyle () {
      return { cursor: this.codeLabel === '获取验证码' ? 'pointer' : 'not-allowed' }
    },
    // 验证码提示字
    codeLabel () {
      return this.time > 0 ? this.time + 's' : '获取验证码'
    },
  },
  methods: {
    // 获取验证码逻辑
    getCode () {
      let _this = this
      if (!/^(0|86|17951)?(13[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|14[0-9]|19[0-9])[0-9]{8}$/.test(_this.phone)) {
        _this.$message.error('手机号格式不正确')
        return
      }

      _this.$api.sendSms({type:'',popularizePhone:_this.phone}).then(res=>{
        if(res?.code){
          _this.$message.error(res.msg)
        }else{
          this.$message.success('发送成功~')
          if (_this.time) return
          _this.time = 60
          const timer = setInterval(() => {
            _this.time --;
            if (!_this.time) clearInterval(timer)
            _this.$on("hook:beforeDestroy", () => clearInterval(timer) )
          }, 1000)
        }
      })

    },
  }
}
