
import FormDialogLessonNew from '@/components/business/form-dialog-lesson-new.vue';
import FormDialogNew from '@/components/business/form-dialog-new.vue';
export default {

    props: {
        type: {
            type: Number,
            default: () => 0
        }
    },
    data () {
        return {
            list: [
                FormDialogNew,
                FormDialogLessonNew,
            ]
        }
    },
}
