// import { Toast } from 'vant'
import {
  Message
} from 'element-ui'
import apiEntire from '@/api'
import methods from '@/utils/methods.js'
export default ({ app, $axios, store, redirect }, inject) => {
  $axios.defaults.baseURL = process.client ? '/api' : process.env.BASE_URL
  $axios.defaults.timeout = 100000
  $axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'

  // 需要添加bd_vid的url路径名单
const bd_vid_list = ['/website/addNewWebsiteData']
  // 请求拦截
  $axios.onRequest((config) => {
    config.headers.channel = 'backend'
    if (config.url.includes(bd_vid_list)) {
      config.data.url = methods.getSessionStorage('bd_vid') || ''
    }
  })

  // 服务器返回异常拦截
  $axios.onError((error) => {
    return error
  })

  // 接口数据返回拦截
  $axios.onResponse((response) => {
    if(response?.data?.code == 0){
      return response?.data.data
    }else{
      Message.error(response?.data?.msg || '服务端响应错误')
      return response?.data
    }
  })


  const API = {}
  for (const i in apiEntire) {
    // 调用api时候的参数 
    // 第一位为 post参数 第二位为get参数
    API[i] = function(data = '', params = '') {
      const { url, method = 'post', headers } = { ...apiEntire[i] }
      return $axios({
        url,
        method,
        headers,
        data,
        params
      })
    }
  }
  app.api = API
  inject('api', API)
}