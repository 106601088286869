import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)


export const state =  () => ({
  dialogVisible: false,
  dialogTypeIndex: 0,
  dialogNewVisible: false,
  dialogNewTypeIndex: 0,
  submitSuccessVisible: false
})

export const actions = {
  setSubmitSuccessVisible (cxt, visible, delay=2000) {
    cxt.commit('setSubmitSuccessVisible', visible);
    if (visible) {
      setTimeout(() => {
        cxt.commit('setSubmitSuccessVisible', false);
      }, delay)
    }
    
  }
}

export const mutations = {
  setDialogVisible(state, dialogVisible) {
    state.dialogVisible = dialogVisible
  },
  setDialogTypeIndex(state, dialogTypeIndex) {
    state.dialogTypeIndex = dialogTypeIndex
  },

  setNewDialogVisible(state, dialogVisible) {
    state.dialogNewVisible = dialogVisible
  },
  setNewDialogTypeIndex(state, dialogTypeIndex) {
    state.dialogNewTypeIndex = dialogTypeIndex
  },

  setSubmitSuccessVisible(state, visible) {
    state.submitSuccessVisible = visible;
  },
}

export const getters = {
  dialogVisible (state) {
    return state.dialogVisible
  },
  dialogTypeIndex (state) {
    return state.dialogTypeIndex
  },
  dialogNewVisible (state) {
    return state.dialogNewVisible
  },
  dialogNewTypeIndex (state) {
    return state.dialogNewTypeIndex
  },
  submitSuccessVisible (state) {
    return state.submitSuccessVisible
  }
}