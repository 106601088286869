import * as computed from './computed' 

let directive = {
  ...computed,
}

export default {
  install(Vue) {
    Object.keys(directive).forEach((key) => {
      Vue.directive(key, directive[key])
    })
  },
}
