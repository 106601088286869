export default ({app, store }) => {
  // const setKeepAlivePage = (to, from) => {
  //   var toIsKeepAlivePage = false
  //   var fromIsKeepAlivePage = false
  //   var keepAlivePageArray = [...store.getters.keepAlivePage]
  //   var deleteIndex = 0
  //   for (var i = 0; i < keepAlivePageArray.length; i++) {
  //     if (keepAlivePageArray[i] === to.name) {
  //       // 记录即将跳转的页面是否已被缓存
  //       toIsKeepAlivePage = true
  //     }
  //     if (keepAlivePageArray[i] === from.name) {
  //       // 记录跳转前页面是否已被缓存
  //       fromIsKeepAlivePage = true
  //       deleteIndex = i
  //     }
  //   }
  //   // 判断是否需要删除缓存路径
  //   if (fromIsKeepAlivePage && toIsKeepAlivePage) {
  //     // 该判断结果标识当前页面跳转操作为“返回”
  //     keepAlivePageArray.splice(deleteIndex, 1)
  //     store.commit('setIsForward', false)
  //   }
  //   // 判断是否需要添加缓存路径
  //   if (!toIsKeepAlivePage) {
  //     keepAlivePageArray.push(to.name)
  //     store.commit('setIsForward', true)
  //   }
  //   // 更新缓存路径的数组
  //   store.commit('setKeepAlivePage', keepAlivePageArray)
  // }
  app.router.beforeEach((to, from, next) => {
    next()
  })
}