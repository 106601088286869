export default {
    //关于玺承发展历程
    development_path: [
        {
            year: '2023',
            awardText: [
                '南京市委书记韩立明，市委常委、秘书长蒋跃建，市领导吴炜、黄辉，区委书记闵一峰一行莅临参访指导',
                '受邀参加“中国(南京)国际电商产业博览会”',
                '与南京市区商务局签约达成战略合作',
                '深度参与由贸促会和商务局联合发布的“跨境电商人才保障计划”',
                '荣获中国国际电商产业博览会“2022年度卓越综合服务企业”奖',
                '与国内多所高校达成战略合作，共同探索“产教融合”的新型生态链',
            ]
        },
        {
            year: '2022',
            awardText: [
                '南京新电商产业学院授予“副理事长单位',
                '荣获“华南电商最佳培训机构”',
                '荣获“中国最佳电商培训机构奖”',
                '受邀分享南京时尚消费直播大赛',
            ]
        },
        {
            year: '2021',
            awardText: [
                '成立电商服务品牌：云玺汇',
                '成立电商组织研学中心专注解决电商企业组织建设问题',
                '南京电子商务协会授予“副理事长单位”',
                '受邀分享深圳礼品展论坛',
                '受邀分享深圳国际内衣峰会',
                '荣获“2021年度最佳电商培训机构”',
                '荣获中国内衣行业SIUF金肚兜奖',
                '“2021年度营销贡献奖”',
                '巴马瑶族自治县明德公益慈善联合会授予“公益慈善伙伴单位”',
                '巴马瑶族自治县明德公益慈善联合会授予创始人“贡献之星”荣誉'
            ]
        },
        {
            year: '2020',
            awardText: [
                '深度布局电商消费级行业投融资服务',
                '担任蚌埠网商协会顾问',
                '担任泗阳电子商务协会顾问',
                '荣获电商金标奖“最佳品牌价值奖”',
                '荣获“2020年度优秀培训机构”奖项',
                '深圳电商选品展授予“最具电商教育影响力品牌机构”',
                '全球创始人大会授予“最具向上影响力企业”荣誉奖项',
                '受邀分享2020国际直播电商论坛',
                '受邀分享2020数字文化新活力高峰论坛暨广州“互联网超级星”颁奖盛典',
                '与蚌山跨境电商园达成战略合作，成为战略合作伙伴'
            ]
        },
        {
            year: '2019',
            awardText: [
                '成立电商在线教育品牌：玺承云学堂',
                '成立玺承亿元俱乐部专注于服务亿级电商企业',
                '受邀分享“第五届全球母婴万人大会”',
                '受邀分享“日本买手联盟，夏季买家峰会”',
                '受邀分享2019GECIA·中国数字电商广州峰会',
                '成为广州电子商务协会副会长单位',
                '广州商务局授予“华南电商最佳培训机构”',
                '与国务院国资委商业发展中心联合主办“首届全球创新创业大会GCIE”',
                '国务院国资委商业发展中心授予创始人“优秀创新企业家代表”'
            ]
        },
        {
            year: '2018',
            awardText: [
                '广东省商务厅认证电商孵化基地',
                '成为宁海县电子商务促进会理事单位',
                '深圳市市场管理局授予广东省“守合同重信用”企业',
                '中国电子商务协会授予“全国电商十佳教育机构”',
                '荣获电商金标奖“全国最佳电商培训贡献奖”'
            ]
        },
        {
            year: '2017',
            awardText: [
                '自主研发软件《玺承大数据平台》',
                '成为顺德电子商务协会理事单位',
                '成为石狮市电子商务协会副会长单位',
                '成为深圳市商业联合会常务理事单位',
                '荣获企业信用评价AAA级信用企业',
                '作为深圳电子商务论坛圆桌嘉宾分享',
                '受邀广州电商峰会进行分享',
                '与青岛即墨工信局达成战略合作'
            ]
        },
        {
            year: '2016',
            awardText: [
                '成立DGM电商数据増长模型研究中心',
                '成立玺承视觉为电商企业提供全案视觉服务',
                '与顺丰电商产业园达成合作',
                '协办首届中国（南京）电商峰会',
                '帮扶贵州省三都水族职业学校电商专业发展 '
            ]
        },
        {
            year: '2015',
            awardText: [
                '举办玺承首届电商行业峰会',
                '与中欧商学院合作举办电商经营课程'
            ]
        },
        {
            year: '2014',
            awardText: [
                '开展全国业务布局',
                '推出电商海外游学项目'
            ]
        },
        {
            year: '2013',
            awardText: [
                '创立电商俱乐部项目',
                '通过全案咨询模式深度服务电商企业'
            ]
        },
        {
            year: '2012',
            awardText: [
                '公司成立',
                '开展电商培训咨询业务',
                '成为深圳市电子商务协会理事单位',
                '荣获年度广州市电子商务创新企业'
            ]
        }
    ],
    teachers: [
        '阿不', '清风', '嵩岳', '排山', '无双', '金戈', '懂懂', '乘风', '擎天', '大圣', '旭日', '铁骨', '万里', '明扬', '琳琅',
        '得天', '大城', '皓月', '雷霆', '千方', '向荣', '风逸', '止境', '和光', '天天', '不凡', '持明', '力行', '云天', '长风',
        '沐风', '博古', '熊猫', '金刚', '百川', '思远', '无涯', '致远', '无缺', '一帆', '子谦', '山峰', '凌霄', '雪豹', '百炼',
        '风行', '无畏', '傲雪', '云霄', '同舟', '心一', '三阳', '逍遥', '山河', '星火', '钟灵', '静怡', '非凡', '千钧', '精宏',
        '甘来', '乔木', '风云', '得一', '龙骧', '一帜', '山语', '秋实', '若水', '南秀', '思源', '沐春', '大禹', '风华', '虚竹',
        '风驰', '若谷', '一言', '鸿一', '青云', '一鸣', '了然', '光年', '一席', '凌空', '灵犀', '一诺', '一衣', '止水', '朝夕',
        '一苇', '铁马', '景行', '乔峰', '笃信', '东风', '方升', '长空', '江左'
    ],
    //公开课
    dilemmaList: [
        {
            svg_name: 'combat-dilema',
            tag: '不系统',
            des: '企业没有系统的电商经营体系，缺乏持续做好做强的底层逻辑'
        },
        {
            svg_name: 'combat-dilema',
            tag: '人才缺',
            des: '企业经营不稳定，缺乏组织构建能力，缺乏人才培养流程体系'
        },
        {
            svg_name: 'combat-dilema',
            tag: '付费高',
            des: '平台免费流量难获取，付费流量投产低，缺乏系统营销推广策略，企业经营困难'
        },
        {
            svg_name: 'combat-dilema',
            tag: '竞争大',
            des: '同行竞争激烈，面临专业团队生存挤压，企业盈利弱'
        },
        {
            svg_name: 'combat-dilema',
            tag: '变化快',
            des: '平台规则变化快，市场风口稍纵即逝，突围能力弱'
        },
        {
            svg_name: 'combat-dilema',
            tag: '爆款难',
            des: '爆款打了也亏钱，不打又没流量；打起来也守不住'
        },
        {
            svg_name: 'combat-dilema',
            tag: '创新难',
            des: '自我迭代慢，企业持续突破难，缺乏智囊团'
        },
    ],
    //公开课
    landingPlanList: [
        {
            icon_name: '',
            icon_text: '预 聚焦问题'
        },
        {
            icon_name: '',
            icon_text: '习 落地方案'
        },
        {
            icon_name: '',
            icon_text: '学 解决思路'
        },
        {
            icon_name: '',
            icon_text: '用 执行反馈'
        },
        {
            icon_name: '',
            icon_text: '链 资源链接'
        },
    ],
    mainCourse: [
        {
            icon_name: '',
            icon_text: '产品布局'
        },
        {
            icon_name: '',
            icon_text: '流量变革'
        },
        {
            icon_name: '',
            icon_text: '转化系统'
        },
        {
            icon_name: '',
            icon_text: '团队搭建'
        },
        {
            icon_name: '',
            icon_text: '盈利模式'
        },
        {
            icon_name: '',
            icon_text: '爆款打法'
        },
        {
            icon_name: '',
            icon_text: '利润核算'
        },
        {
            icon_name: '',
            icon_text: '平台信号'
        },
        {
            icon_name: '',
            icon_text: '人效提升'
        },
        {
            icon_name: '',
            icon_text: '店群裂变'
        },
        {
            icon_name: '',
            icon_text: '新品运营'
        },
        {
            icon_name: '',
            icon_text: '成长规律'
        },
        {
            icon_name: '',
            icon_text: '客户管理'
        },
    ],
    courseTag: [
        {
            img_name: 'combat-dilema',
            tag: '战略',
            des: '选择赛道，找到利润方向'
        },
        {
            img_name: 'combat-dilema',
            tag: '运营',
            des: '标准体系，提升运营效率'
        },
        {
            img_name: 'combat-dilema',
            tag: '组织',
            des: '优化结构，提升企业人效'
        },
        {
            img_name: 'combat-dilema',
            tag: '创新',
            des: '知识升级，发现新增长点'
        },
    ],
    caseDataSource: [
        {
            "before": {
                "c": "月销",
                "d": "90w"
            },
            "after": {
                "c": "月销",
                "d": "300w"
            },
            "type": "抖音",
            "catalog": "女装",
            "content": "学习前抖音团队刚组建，直播间正价转不动，在抖音电商上没有方法；\n学习后对团队人员进行全盘店铺维护起店起号的策略打法，每日/每周/每月制定复盘计划，通过短视频不断测试下，目前每月销售额完成入营前500％的提升。"
        },
        {
            "before": {
                "c": "月销",
                "d": "60w"
            },
            "after": {
                "c": "月销",
                "d": "900w"
            },
            "type": "淘宝",
            "catalog": "家具",
            "content": "合作前：刚开的新店，一直在考虑新链接破0，20年底来学习，当时月销还不到50w；\n合作后：22年月销100w，23年8月月销900万，连续三年不断在输送团队来学习。"
        },
        {
            "before": {
                "c": "月销",
                "d": "240w"
            },
            "after": {
                "c": "月销",
                "d": "900w"
            },
            "type": "淘宝",
            "catalog": "户外用品",
            "content": "合作前：供应链强大，因整体流量玩法和布局的问题，导致一年做几千万也不盈利；\n合作后：第一阶段从原有爆款的流量渠道布局调整；第二阶段搭建爆款模型；第三阶段完成整个年度规划布局。2023年销售额目标一个亿。"
        },
        {
            "before": {
                "c": "月销",
                "d": "24w"
            },
            "after": {
                "c": "月销",
                "d": "690w"
            },
            "type": "淘宝",
            "catalog": "食品",
            "content": "合作前：店铺做的就很不错，但新客获取难，老客活跃下降，想提升免费流量；\n合作后：通过站内免费流量获取，粉丝积累，站外小红书大量种草，达人合作，站内收割，目前持续多渠道引流，结合抖音做品牌输出。"
        },
        {
            "before": {
                "c": "月销",
                "d": "0"
            },
            "after": {
                "c": "月销",
                "d": "240w"
            },
            "type": "抖音",
            "catalog": "日用百货",
            "content": "学习前团队只有3人，属于新店新号，一直不盈利；\n学习后完善商场布局，拓宽产品维度，带动短视频和商品卡的占比，不开直播，加强达人板块，一星期破零，店铺上榜。"
        },
        {
            "before": {
                "c": "月销",
                "d": "0"
            },
            "after": {
                "c": "月销",
                "d": "990w"
            },
            "type": "抖音",
            "catalog": "食品",
            "content": "合作前：工厂型企业，以供应商形式进行供货，今年新入局线上抖音赛道，团队与人员经验全无；\n合作后：从0开始，按照导师规划进行人员招聘、培训、直播间搭建、细分赛道的选品等布局，迅速组建直播团队，并完成了账号的冷启动期，在中秋月饼赛道完成销售额1000万的突破。"
        },
        {
            "before": {
                "c": "月销",
                "d": "1700w"
            },
            "after": {
                "c": "月销",
                "d": "1.2亿"
            },
            "type": "抖音",
            "catalog": "美容护肤",
            "content": "合作前：在当地很知名，企业规模2个亿，但是这个规模一直卡住了，来玺承就是想要突破销售额。\n合作后：主要通过俱乐部大数据系统找到了趋势增长的品类做了布局，另外就是借助抖音的外部流量导入，实现了大爆款大打造。同时还布局一个专卖店，所有在一年的时间了就从2个亿做到了15个亿。"
        },
        {
            "before": {
                "c": "月销",
                "d": "450w"
            },
            "after": {
                "c": "月销",
                "d": "1200w"
            },
            "type": "淘宝",
            "catalog": "五金工具",
            "content": "合作前：学员是无货源多店铺运营情况，已有一定的销售基础；\n合作后：重新梳理了整体运营的标准化秩序、店铺推广的秩序和节奏方案，使其整体团队多店铺得到了营业额和利润的3-4倍提升。"
        },
        {
            "before": {
                "c": "月销",
                "d": "60w"
            },
            "after": {
                "c": "月销",
                "d": "390w"
            },
            "type": "淘宝",
            "catalog": "3c数码",
            "content": "合作前：浩瀚在国外是云台类目Top，一个月外贸能做1200w，淘系几个店铺加一起月销100万，流量不知道如何突破层级；\n合作后：调研行业竞争对手的营销卖点，精细化构建产品竞争力，成功跻身行业Top店铺。"
        },
        {
            "before": {
                "c": "月销",
                "d": "120w"
            },
            "after": {
                "c": "月销",
                "d": "1950w"
            },
            "type": "淘宝",
            "catalog": "食品",
            "content": "合作前：店铺就已经做的很好；\n合作后：重新梳理店铺产品线，通过趋势节点布局和快速打造小爆款群梯队方式实现销售额提升。"
        },
        {
            "before": {
                "c": "月销",
                "d": "18w"
            },
            "after": {
                "c": "月销",
                "d": "900w"
            },
            "type": "抖音",
            "catalog": "家居收纳",
            "content": "学习前日销不稳定，付费占比高，团队协同效率低；学习后短视频提高日产效率，重新规划区分素材结构与目的；通过布局多平台，做账号产品差异化和价格带差异化的区分，再以达人合作的形式，扩大品牌声望。"
        },
        {
            "before": {
                "c": "月销",
                "d": "60w"
            },
            "after": {
                "c": "月销",
                "d": "600w"
            },
            "type": "抖音",
            "catalog": "文具",
            "content": "合作前：想解决库存问题和代运营长时间亏损问题；\n合作后：重新调整付费打法，优化素材产出流量，从原本的外包素材，优化到每日稳定自制7-10条视频，提高产出效率。通过对用户的心理分析，进行产品优势的塑造与更迭。"
        },
        {
            "before": {
                "c": "月销",
                "d": "90w"
            },
            "after": {
                "c": "月销",
                "d": "900w"
            },
            "type": "淘宝",
            "catalog": "花卉仿真",
            "content": "合作前：老板是创二代，店铺做的比较差；\n合作后：进行了市场分析，据用户属性与特征，调整了消费人群。不仅赶上了市场红利，目前盈利收益也非常可观。"
        },
        {
            "before": {
                "c": "月销",
                "d": "60w"
            },
            "after": {
                "c": "月销",
                "d": "270w"
            },
            "type": "淘宝",
            "catalog": "保健食品",
            "content": "合作前：渠道资源都不错，在品牌竞争力和爆款打造版块存在问题；\n合作后：重新做了品类和供应链盘点，通过不同场景/不同人群/不同价格带布局，再以人群划分/场景划分/价格划分，多链接布局，实现9万日销。"
        },
        {
            "before": {
                "c": "月销",
                "d": "120w"
            },
            "after": {
                "c": "月销",
                "d": "300w"
            },
            "type": "淘宝",
            "catalog": "箱包",
            "content": "在外面参加过很多培训，有不错的运营技术基础，但是市场分析和数据分析非常薄弱，店铺总是卡在一天4万内无法突破；\n合作后：在老师帮助下，很快找到了店铺的突破口，根据不同关键词、市场和人群需求，裂变现有的爆款，现在日均保持在10万左右。"
        },
        {
            "before": {
                "c": "月销",
                "d": "0"
            },
            "after": {
                "c": "月销",
                "d": "150w"
            },
            "type": "抖音",
            "catalog": "玩具",
            "content": "学习前店铺封禁，从0开始；学习后重新启动新店铺，之前账号固有的拍摄方式很难给直播间引流，因此针对直播间的拆卡高光人为录制切片并进行二次剪辑，加大直播间精准引流进入转化，3天内短视频千次观看成交从￥6到￥784，直播间入口点击从1.2％提升到5％，目前在布局第三个账号。"
        },
        {
            "before": {
                "c": "月销",
                "d": "12w"
            },
            "after": {
                "c": "月销",
                "d": "120w"
            },
            "type": "淘宝",
            "catalog": "个人洗护",
            "content": "合作前：老板是一个小白，不懂什么运营知识，公司也没有运营，因外贸生意不好做开始转做国内；\n合作后：逐个在不同品类词50-200客单价进行了突围，进入到第6层级，实现了高利润增长。公司从2个人发展到10个人。"
        },
        {
            "before": {
                "c": "月销",
                "d": "90w"
            },
            "after": {
                "c": "月销",
                "d": "600w"
            },
            "type": "抖音",
            "catalog": "女鞋",
            "content": "学习前夫妻俩从温州工厂拿货，对抖音不太了解，玩法付费都不会；学习后了解了抖音的模式玩法和标准化流程，通过直播间围绕付费+单爆品模式，同时在短视频内容端口持续发素材，通过视频引流到直播间，进而把产品的销量打爆，目前账号每日销售额平均15万-20万一天。"
        },
        {
            "before": {
                "c": "月销",
                "d": "18w"
            },
            "after": {
                "c": "月销",
                "d": "90w"
            },
            "type": "淘宝",
            "catalog": "包装",
            "content": "合作前：有运营基础，一直没有出来合作过，有好几个店一直打不起来；\n合作后：通过老师给的一对一定制方案从原先20w不到提升到96w，还在持续上涨，目前进入行业前20。"
        },
        {
            "before": {
                "c": "月销",
                "d": "120w"
            },
            "after": {
                "c": "月销",
                "d": "300w"
            },
            "type": "淘宝",
            "catalog": "家装建材",
            "content": "合作前：市场拿货，没有供应链优势；\n合作后：一个月的时间从一个单品0到第二价格段搜索第一名，手把手的带着如何分析竞品数据，优化视觉，主图点击率等从一天4万到10万一天。"
        },
        {
            "before": {
                "c": "月销",
                "d": "60w"
            },
            "after": {
                "c": "月销",
                "d": "600w"
            },
            "type": "抖音",
            "catalog": "食品生鲜",
            "content": "学习前已亏损200万，且持续每天亏损；学习后千川投放由粗放的通投纠正为精细化投放为主，并且打通了素材批量化生产和千川投手两个部门的衔接和逻辑以及工作流程，在消耗提高的情况下转化成本在降低，roi也在稳步提升。"
        },
        {
            "before": {
                "c": "月销",
                "d": "450w"
            },
            "after": {
                "c": "月销",
                "d": "900w"
            },
            "type": "淘宝",
            "catalog": "影音电器",
            "content": "合作前：企业做代理品牌，市场竞争非常激烈，而且行业都刷单，进班时老板特别焦虑；\n合作后：从关键词市场做爆款突围，通过点击率系统与转化率系统优化，达到了除品牌旗舰店以外的第一名。并帮助企业做选品增量规划，现在企业目标非常清晰。"
        },
        {
            "before": {
                "c": "月销",
                "d": "300w"
            },
            "after": {
                "c": "月销",
                "d": "1200w"
            },
            "type": "抖音",
            "catalog": "家居布艺",
            "content": "学习前企业内部存在很多问题，团队分工不清晰，各个端口协同能力待提升；学习后重新搭建管理体系、绩效考核、晋升通道的标准化流程，从以前单账户到现在多账号布局，不再一味的做直播拉时长，而是提高人效和成交效率，现在是全域布局，月销售额1200万＋。"
        },
        {
            "before": {
                "c": "月销",
                "d": "0"
            },
            "after": {
                "c": "月销",
                "d": "60w"
            },
            "type": "抖音",
            "catalog": "服饰配件",
            "content": "学习前不懂抖音，都是交给团队，做的很一般；学习后重新梳理了抖音的变现逻辑，结合市场数据化选品，前期不开直播，通过短视频带货+商品卡+达人端口等，拉升产品链接销量，再来开直播。新账号从0起步，不开播最高能2万一天。"
        },
        {
            "before": {
                "c": "月销",
                "d": "450w"
            },
            "after": {
                "c": "月销",
                "d": "900w"
            },
            "type": "淘宝",
            "catalog": "西服",
            "content": "合作前：销售额大概15万一天，做的非常不错，但是渠道运营较薄弱；\n合作后：通过对比搜索和直通车关键词，帮助企业优化付费推广渠道，总体销售额相对进班前增加了30%多，同时付费占比降低了20%。现在在衣服套装类目是top商家。"
        },
        {
            "before": {
                "c": "月销",
                "d": "300"
            },
            "after": {
                "c": "月销",
                "d": "30w"
            },
            "type": "抖音",
            "catalog": "农机产品",
            "content": "学习前学员是纯小白，新店铺，还没出体验分；学习后从小白成长为一个有基本抖音电商运营能力的商家，掌握了市场分析及商品布局能力，学会了千川的短视频图文投放逻辑以及超级商品卡的千川投放，近期的投放已经盈利。"
        },
        {
            "before": {
                "c": "月销",
                "d": "180w"
            },
            "after": {
                "c": "月销",
                "d": "900w"
            },
            "type": "抖音",
            "catalog": "白酒",
            "content": "合作前：集团负责人刚接手抖音项目，急需在线上做出销量增长；\n合作后：2个月的时间通过对企业在抖音电商上的全盘策略进行了指导，短视频拍摄方向和思路进行了调整，成长较入班前提升80％以上。"
        },
        {
            "before": {
                "c": "月销",
                "d": "3w"
            },
            "after": {
                "c": "月销",
                "d": "210w"
            },
            "type": "淘宝",
            "catalog": "绿植园艺",
            "content": "合作前：只会刷单，企业在产业带，夫妻2个没有运营能力；\n合作后：根据市场竞争度，选择避开竞争激烈的盆栽市场，找了趋势节点明显的产品，目前桂花树打到了第一。"
        },
        {
            "before": {
                "c": "月销",
                "d": "90w"
            },
            "after": {
                "c": "月销",
                "d": "210w"
            },
            "type": "淘宝",
            "catalog": "医疗器械",
            "content": "合作前：现有医疗器械市场打爆款原来处于行业第三的位置，团队没有标准化体系；\n合作后：通过精细化运营，标准化爆款流程成功打到行业第一，从日销2.5万到现在平均日销9万左右，实现了销售额突破。"
        },
        {
            "before": {
                "c": "月销",
                "d": "30w"
            },
            "after": {
                "c": "月销",
                "d": "270w"
            },
            "type": "抖音",
            "catalog": "住宅家具",
            "content": "学习前多平台直播，日销不稳定；学习后通过微付费三频共振玩法，直播间＋短视频＋商品卡建立产品护城河，直播间成交模型稳定进行复制；短视频端口提升产出效率，放大流量占比。"
        },
        {
            "before": {
                "c": "月销",
                "d": "90w"
            },
            "after": {
                "c": "月销",
                "d": "150w"
            },
            "type": "抖音",
            "catalog": "地毯",
            "content": "学习前自有工厂，行业客单价天花板；学习后对产品重新布局和定价，直播间只做高端产品，不放低价产品，重新培训短视频批量化生产好的素材以及千川投放逻辑和方法，目前千川计划一步一步稳步搭建，整体投产和消耗都上去了。"
        },
        {
            "before": {
                "c": "月销",
                "d": "6w"
            },
            "after": {
                "c": "月销",
                "d": "300w"
            },
            "type": "淘宝",
            "catalog": "全屋定制",
            "content": "合作前：C店销排第一的爆款给玺承学员超越了；\n合作后：用天猫店实现了反超，再次重返第一，2022年实现净利润800万；两个月内实现大爆款拿回销排第一。"
        },
        {
            "before": {
                "c": "月销",
                "d": "7k"
            },
            "after": {
                "c": "月销",
                "d": "300w"
            },
            "type": "淘宝",
            "catalog": "雨伞",
            "content": "合作前：老板是家族企业，店铺做了一年，一天只卖几十元，几乎是不卖的状态；\n合作后：打了三个爆款，第一阶段重新梳理了转化率系统，一个月后销售额从50元升到5万左右。第二阶段布局了第二个爆款链接，目前日销维持十万以上。"
        },
        {
            "before": {
                "c": "月销",
                "d": "4w"
            },
            "after": {
                "c": "月销",
                "d": "450w"
            },
            "type": "抖音",
            "catalog": "戏水玩具",
            "content": "学习前，基础知识薄弱，多个不同一级类目产品再同个店铺混销，店铺不盈利；学习后，通过直播+短视频+商品卡系统化的布局，优化直播视觉，多账号矩阵开播，拓宽店铺产品，结合小件商品动销 opm承接商城红利流量；日均15~20w。"
        }
    ],
    clienSay: [
        {
            type: '淘宝天猫',
            title: '8年老店濒临倒闭，来玺承业绩逆风翻3倍',
            video: 'https://videostatic.xcect.com/vod-57ac9a/sv/17c1ebc7-18efe8d4812/17c1ebc7-18efe8d4812.mp4',
            url: 'https://pz.xcect.com/ossImg/officialWebsite/PC/common/19_video_cover.png',
        }, {
            type: '淘宝天猫',
            title: '亿级卖家慕名来到玺承，一个动作业绩提升20%',
            video: 'https://videostatic.xcect.com/vod-57ac9a/sv/595fa8df-18efe8d4817/595fa8df-18efe8d4817.mp4',
            url: 'https://pz.xcect.com/ossImg/officialWebsite/PC/common/20_video_cover.png',
        }, {
            type: '淘宝天猫',
            title: '6天6夜效果太震撼，后悔报了名3年后才学习',
            video: 'https://videostatic.xcect.com/vod-57ac9a/sv/3991a995-18efe8d47fc/3991a995-18efe8d47fc.mp4',
            url: 'https://pz.xcect.com/ossImg/officialWebsite/PC/common/21_video_cover.png',
        }, {
            type: '淘宝天猫',
            title: '淘宝天猫盈利突破特训营，一线学员成长见证',
            video: 'https://videostatic.xcect.com/vod-57ac9a/sv/581a0144-18efe8d4810/581a0144-18efe8d4810.mp4',
            url: 'https://pz.xcect.com/ossImg/officialWebsite/PC/common/22_video_cover.png',
        }, {
            type: '拼多多',
            title: '踩了拼多多这么多坑后，感慨认识玺承太晚了',
            video: 'https://videostatic.xcect.com/vod-57ac9a/sv/17299020-18efe8d480a/17299020-18efe8d480a.mp4',
            url: 'https://pz.xcect.com/ossImg/officialWebsite/PC/common/18_video_cover.png',
        }, {
            type: '淘宝天猫',
            title: '从小白到运营高手他做对了哪一步！',
            video: 'https://videostatic.xcect.com/vod-57ac9a/sv/1ff9020-18ca4068691/1ff9020-18ca4068691.mp4',
            url: 'https://pz.xcect.com/ossImg/officialWebsite/PC/common/1_video_cover_tx_1.png',
        }, {
            type: '财税股权',
            title: '给再多员工都对立，巧用股权实现税后收入',
            video: 'https://videostatic.xcect.com/vod-57ac9a/sv/4081b318-18ca4069195/4081b318-18ca4069195.mp4',
            url: 'https://pz.xcect.com/ossImg/officialWebsite/PC/common/2_video_cover_cs_1.png',
        }, {
            type: '淘宝天猫',
            title: '坚持跟玺承做邻居？他到底学到了什么',
            video: 'https://videostatic.xcect.com/vod-57ac9a/sv/5d2a621f-18ca4069433/5d2a621f-18ca4069433.mp4',
            url: 'https://pz.xcect.com/ossImg/officialWebsite/PC/common/3_video_cover_tx_2.png',
        }, {
            type: '抖音',
            title: '什么是电商人，都想实现的终极梦想！',
            video: 'https://videostatic.xcect.com/vod-57ac9a/sv/363d188a-18ca4065246/363d188a-18ca4065246.mp4',
            url: 'https://pz.xcect.com/ossImg/officialWebsite/PC/common/4_video_cover_dy_1.png',

        }, {
            type: '拼多多',
            title: '淘系思维用在拼多多，抓准一点想不卖都不行',
            video: 'https://videostatic.xcect.com/vod-57ac9a/sv/167a8f29-18ca4065256/167a8f29-18ca4065256.mp4',
            url: 'https://pz.xcect.com/ossImg/officialWebsite/PC/common/5_video_cover_pdd_1.png',
        }, {
            type: '淘宝天猫',
            title: '再次来玺承，有些弯路不必走',
            video: 'https://videostatic.xcect.com/vod-57ac9a/sv/358603d9-18ca406528d/358603d9-18ca406528d.mp4',
            url: 'https://pz.xcect.com/ossImg/officialWebsite/PC/common/6_video_cover_tx_3.png',
        }, {
            type: '抖音',
            title: '学完成为行业TOP，为何再回来“复读”',
            video: 'https://videostatic.xcect.com/vod-57ac9a/sv/56157e8c-18ca4065265/56157e8c-18ca4065265.mp4',
            url: 'https://pz.xcect.com/ossImg/officialWebsite/PC/common/7_video_cover_dy_6.png',
        }, {
            type: '小红书',
            title: '运营上有缺陷？缺啥咱就补啥',
            video: 'https://videostatic.xcect.com/vod-57ac9a/sv/198b25e2-18ca4065259/198b25e2-18ca4065259.mp4',
            url: 'https://pz.xcect.com/ossImg/officialWebsite/PC/common/8_video_cover_xhs_1.png',
        }, {
            type: '淘宝天猫',
            title: '刷单成瘾？请警惕这“致命"诱惑',
            video: 'https://videostatic.xcect.com/vod-57ac9a/sv/17dec19d-18ca406576d/17dec19d-18ca406576d.mp4',
            url: 'https://pz.xcect.com/ossImg/officialWebsite/PC/common/9_video_cover_tx_4.png',
        }, {
            type: '抖音',
            title: '入局抖音1年都没起色，是什么让她信心倍增',
            video: 'https://videostatic.xcect.com/vod-57ac9a/sv/5d0d785e-18ca40657ae/5d0d785e-18ca40657ae.mp4',
            url: 'https://pz.xcect.com/ossImg/officialWebsite/PC/common/10_video_cover_dy_5.png',
        }, {
            type: '财税股权',
            title: '有课必报？是盲从还是真收获！',
            video: 'https://videostatic.xcect.com/vod-57ac9a/sv/fd48f2f-18ca40657c2/fd48f2f-18ca40657c2.mp4',
            url: 'https://pz.xcect.com/ossImg/officialWebsite/PC/common/11_video_cover_cs_3.png',
        }, {
            type: '淘宝天猫',
            title: '听运营的话掉沟里，刷单这坑还敢踩吗',
            video: 'https://videostatic.xcect.com/vod-57ac9a/sv/53e0bb2-18ca406580b/53e0bb2-18ca406580b.mp4',
            url: 'https://pz.xcect.com/ossImg/officialWebsite/PC/common/12_video_cover_tx_5.png',
        }, {
            type: '抖音',
            title: '一套能落地的方案，才能实现上下同欲',
            video: 'https://videostatic.xcect.com/vod-57ac9a/sv/b2923dc-18ca4065945/b2923dc-18ca4065945.mp4',
            url: 'https://pz.xcect.com/ossImg/officialWebsite/PC/common/13_video_cover_dy_2.png',
        }, {
            type: '淘宝天猫',
            title: '从3干万到超4亿销售额，做了什么实现扭亏为赢',
            video: 'https://videostatic.xcect.com/vod-57ac9a/sv/1f10dce-18ca4066089/1f10dce-18ca4066089.mp4',
            url: 'https://pz.xcect.com/ossImg/officialWebsite/PC/common/14_video_cover_tx_6.png',
        }, {
            type: '抖音',
            title: '一套方案立刻落地，省钱省力省时间',
            video: 'https://videostatic.xcect.com/vod-57ac9a/sv/3acc99a3-18ca4066128/3acc99a3-18ca4066128.mp4',
            url: 'https://pz.xcect.com/ossImg/officialWebsite/PC/common/15_video_cover_dy_4.png',
        }, {
            type: '财税股权',
            title: '从点到面看世界，实现电商财务专业化',
            video: 'https://videostatic.xcect.com/vod-57ac9a/sv/5f466dbf-18ca4066216/5f466dbf-18ca4066216.mp4',
            url: 'https://pz.xcect.com/ossImg/officialWebsite/PC/common/16_video_cover_cs_2.png',
        }, {
            type: '抖音',
            title: '掌握成体系的打法，省的远不止100万！',
            video: 'https://videostatic.xcect.com/vod-57ac9a/sv/23e1218d-18ca406627c/23e1218d-18ca406627c.mp4',
            url: 'https://pz.xcect.com/ossImg/officialWebsite/PC/common/17_video_cover_dy_3.png',
        }
    ],
}

