import Vue from 'vue'
// import ElementUI from 'element-ui'
import {
  Message,
  MenuItem,
  MenuItemGroup,
  Menu,
  Form,
  FormItem,
  Input,
  Radio,
  Checkbox,
  Submenu,
  Popover,
  Button,
  Dialog,
  Pagination,
  Image,
  Tabs,
  TabPane,
  Table,
  TableColumn,
  Backtop,
  CheckboxGroup,
  Carousel,
  CarouselItem,
  Select,
  Option,
  Tree,
  DatePicker
} from 'element-ui'
const message = Message
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Menu)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Radio)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Submenu)
Vue.use(Popover)
Vue.use(Pagination)
Vue.use(Button)
Vue.use(Dialog)
Vue.use(Image)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Backtop)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Tree)
Vue.use(Select)
Vue.use(Option)
Vue.use(DatePicker)

export default ({ app, $axios, store, redirect }, inject) => {
  
  inject('message', message)
}