export const computedHeight = {
  inserted: function (el, binding) {
    const classNames = binding.value
    const getHeight = (className) => {
      const dom = document.querySelector(className)
      return dom ? dom.offsetHeight : 0
    }
    el.style.height =
      document.documentElement.clientHeight -
      0.5 -
      classNames.reduce((total, value) => {
        return total + getHeight(value)
      }, 0) +
      'px'
  },
}
