export default {
  // 公开课课程排期
  getTrailProductList: {
    url: '/website/getTrailProductList'
  },
  //根据类目获取案例数据
  getWebsiteCaseByCategory: {
    url: '/website/getWebsiteCaseByCategory'
  },
  //获取常见问题
  getCommonQuestion: {
    url: '/website/getCommonQuestion'
  },
  //获取问答类目
  getWebsiteQaType: {
    url: '/website/getWebsiteQaType'
  }, 
  //(官网)获取问答详情
  getWebsiteSalesQaDetail: {
    url: '/website/getWebsiteSalesQaDetail'
  },
  //模糊搜索问答信息
  getWebsiteSalesQaCondition: {
    url: '/website/getWebsiteSalesQaCondition'
  },
  // 首页课程安排
  getWebSiteClassData: {
    url: '/website/getWebSiteClassData',
  },
  // 获取所有类目
	getAllWebsiteCategory:{
    url: '/website/getAllWebsiteCategory'
	},
  // 提交表单
  addNewWebsiteData: {
    url: '/website/addNewWebsiteData'
  },
  // 获取验证码
  sendSms: {
    url: '/sms/sendSms'
  },
  // 官网岗位投递
  websitePersonAdd: {
    url: '/website/websitePersonAdd'
  },
  getWebsiteList: {
    url: '/website/getWebsiteList'
  },
  getWebsiteCourseData: {
    url: '/website/getWebsiteCourseData'
  },
  getWebsiteTeacherData: {
    url: '/website/getWebsiteTeacherData'
  }
}