
  import VerificationCode from '@/components/business/verification_code.vue';
  import SubmitSuccess from '@/components/business/submit-success.vue';
  export default {
    components: {
      VerificationCode,
      SubmitSuccess
    },
    model: {
      prop: 'visible',
      event: 'change'
    },
    props: {
      visible: {
        type: Boolean,
        default: () => false
      },
      dialogType: {
        type: Number,
        default: () => 0
      }
    },
    data() {
      const validatePhone =  (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请填写手机号'));
        } else if (!/^(0|86|17951)?(13[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|14[0-9]|19[0-9])[0-9]{8}$/.test(value)) {
          callback(new Error('请填写正确的手机号码'));
        } else {
          callback();
        }
      }
      return {
        popularizeEcTypeOps: [ '淘宝', '天猫', '抖音', '财税', '拼多多', '小红书', '京东', 'TikTok', '1688', '亚马逊', '其他'],
        popularizeFocus: [
            '新品新店打法',
            '产品规划布局',
            '爆款运营流程',
            '付费推广玩法',
            '运营标准流程',
            '流量渠道布局',
            '团队绩效管理',
            '视觉转化系统',
            '利润放大模型',
            '客服转化体系',
        ],
        info: {
          companyName: '',
          name: '',
          phone: '',
          code: '',
          time: 0,
        },
        formRules:{
          name: [{ required: true, message: '请填写姓名', trigger: 'submit' }],
          popularizeStoreName: [{ required: true, message: '请填写企业名称', trigger: 'submit' }],
          phone: [{ required: true, message: '请填写手机号', trigger: 'blur' }, { validator: validatePhone, trigger: 'blur' }],
          code: [{ required: true, message: '请填写验证码', trigger: 'submit' }],
          popularizeEcType: [{ required: true, message: '请填写验证码', trigger: 'submit' }],
          popularizeFocus: [{ required: true, message: '请填写验证码', trigger: 'submit' }],
          popularizeRegion: [{ required: true, message: '请填写验证码', trigger: 'submit' }],
        },
      }
    },
    computed: {
      phonePass () {
        return /^(0|86|17951)?(13[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|14[0-9]|19[0-9])[0-9]{8}$/.test(this.info.phone)
      }
    },
    methods: {
      getCode () {
        if (!this.phonePass) return this.$message.warning('请填写手机号')
        this.$api.sendSms({type:'',popularizePhone: this.info.phone}).then(res=>{
          if(res?.code){
            // this.$message.error(res.msg)
          }else{
            this.$message.success('发送成功~')
            if (this.time) return
            this.info.time = 60
            let timer = setInterval(() => {
              this.$on("hook:beforeDestroy", () => clearInterval(timer))
              this.info.time--;
              if (this.info.time <= 0) {
                clearInterval(timer);
              }
            }, 1000)
          }
        })
      },
      handleBeforeClose () {
        this.$emit('change', false)
        this.$refs['elForm'].resetFields()
      },
      submitTip () {
        this.$refs['elForm'].validate()
      },
      submitForm(){
        this.$refs['elForm'].validate((valid) => {
          if (valid) {
            const params = {
              fromPage: location.href.split('?')[0],
              fromType: '与我们联系',
              popularizePhone: this.info.phone,
              popularizeName: this.info.name,
              popularizeStoreName: this.info.popularizeStoreName,
              popularizeVerificationCode: this.info.code,
              popularizeFocus: this.info.popularizeFocus,
              popularizeEcType: this.info.popularizeEcType,
              popularizeRegion: this.info.popularizeRegion,
            }
            this.$api.addNewWebsiteData(params).then(res=>{
              if(res?.code){
                // _this.$message.error(res.msg)
              }else{
                this.$store.dispatch('setSubmitSuccessVisible', true)
                this.$emit('change', false)
                this.$refs['elForm'].resetFields()
              }
            })
          }
        })
      }
    }
  }
  