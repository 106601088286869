const methods = {
  // 缓存数据
  getStorage(data){
    return localStorage.getItem(data)
  },
  setStorage(item, data){
    return localStorage.setItem(item, data)
  },
  removeStorage(item){
    return localStorage.removeItem(item)
  },
  getSessionStorage(item){
    return sessionStorage.getItem(item)
  },
  setSessionStorage(item, data){
    return sessionStorage.setItem(item, data)
  },
  removeSession(item){
    return sessionStorage.removeItem(item)
  },
  getCurrentTime(date) {
		var month = zeroFill(date.getMonth() + 1);//月
		var day = zeroFill(date.getDate());//日
		var hour = zeroFill(date.getHours());//时
		var minute = zeroFill(date.getMinutes());//分
		var second = zeroFill(date.getSeconds());//秒
		
		//当前时间
		var curTime = date.getFullYear() + "-" + month + "-" + day
						+ " " + hour + ":" + minute + ":" + second;
		
		return curTime;
	},
  // 格式化时间 yyyy-MM-dd hh:mm:ss
  formatDate(time, fmt) {
    if (time === undefined || '') {
      return
    }
    const date = new Date(time)
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    const o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'h+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds()
    }
    for (const k in o) {
      if (new RegExp(`(${k})`).test(fmt)) {
        const str = o[k] + ''
        fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str))
      }
    }
    return fmt
  },
  // 复制功能
  copyText(text){
    var input = document.createElement('input')
    input.value = text
    document.body.appendChild(input)
    input.select()
    document.execCommand('Copy')
    document.body.removeChild(input)
  },
  // 判断是否是微信浏览器
  decideBrowser(){
    const ua = navigator.userAgent.toLowerCase()

    const isWeixin = ua.indexOf('micromessenger') !== -1;
    if (isWeixin) {
      return true
    } else {
      return false 
    }
  },
  captureFrame(videoFile,) {
    const video =document.createElement('video');
    video.currentTime =  0.1; // 设置视频停留到指定时间帧
    video.muted = true;
    video.autoPlay = true; // 设置视频加载完后自动播放，这样才能加载到指定时间的画面
    video.src = videoFile;
    video.setAttribute('crossOrigin', 'anonymous')
    return new Promise((resolve) => {
      video.addEventListener('loadeddata',() => {
        const canvas = document.createElement('canvas');
        // 在视频数据加载完成后执行
        const width = 220;
        const height = 220;
        canvas.width = width
        canvas.height = height
        const ctx = canvas.getContext('2d');
        ctx.drawImage(video, 0, 0, width, height);
        let imgDataUrl = canvas.toDataURL('image/png');
        // 将imgDataUrl发送到服务器，或者直接使用它作为封面图
        resolve(imgDataUrl)
      })
    })
    
  }
}

function padLeftZero(str) {
  return ('00' + str).substr(str.length)
}

function zeroFill(i){
  if (i >= 0 && i <= 9) {
      return "0" + i;
  } else {
      return i;
  }
}

export default methods