import Vue from 'vue'
import FormDialog from '@/components/business/form-dialog.vue'
import FormDialogNew from '@/components/business/form-dialog-new-main.vue';
// import OfficeBtn from '@/components/common/office_btn.vue'
import OfficeBtn from '@/components/common/common_btn.vue'

Vue.mixin({
  methods: {
    openCommonDialog (index=0) {
      this.openCommonDialogNew(1);
      // this.$store.commit('setDialogVisible', true) 
      // this.$store.commit('setDialogTypeIndex', index) 
    },
    openCommonDialogNew () {
      const routeName =  this.$route.name
      const routeList = ['industry', 'capitalism', 'digitalIntel']
      let index = 1;
      if (routeList.includes(routeName)) index = 0;
      this.$store.commit('setNewDialogVisible', true) 
      this.$store.commit('setNewDialogTypeIndex', index) 
    },
  },
});

Vue.component('FormDialog', FormDialog);
Vue.component('FormDialogNew', FormDialogNew);
Vue.component('OfficeBtn', OfficeBtn);
