
import VerificationCode from '@/components/business/verification_code.vue'
export default {
  components: {
    VerificationCode
  },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: {
      type: Boolean,
      default: () => false
    },
    dialogType: {
      type: Number,
      default: () => 0
    }
  },
  data() {
    return {
      params: {
        formFlag:true,
        popularizeCompany:'',
        popularizeName:'',
        popularizePhone:"",
        popularizeEcType:[],
        popularizeFocus:[],
        popularizeRegion:'',
        popularizeStoreName:'',
        popularizeVerificationCode:'',
        fromPage:''
      },
      //表单校验
      formRules:{
        // popularizeCompany: [{ required: true, message: '请输入必填项', trigger: 'submit' }],
        popularizeStoreName: [{ required: true, message: '请输入必填项', trigger: 'submit' }],
        popularizeName: [{ required: true, message: '请输入必填项', trigger: 'submit' }],
        popularizePhone: [{ required: true, message: '请输入必填项', trigger: 'submit' }],
        popularizeFocus: [{ required: true, message: '请输入必填项', trigger: 'submit' }],
        popularizeEcType: [{ required: true, message: '请输入必填项', trigger: 'submit' }],
        popularizeVerificationCode: [{ required: true, message: '请输入必填项', trigger: 'submit' }],
        popularizeRegion: [{ required: true, message: '请输入必填项', trigger: 'submit' }],
      },
      popularizeEcTypeOps: [ '淘宝', '天猫', '京东', '拼多多', '抖音', '快手', '跨境电商', '其他'],
      flag:true,
      dialogTypeList: [
        '了解课程详情',
        '店铺诊断咨询',
      ]
    }
  },
  methods: {
    handleBeforeClose () {
      this.$emit('change', false)
      this.$refs['commonForm'].resetFields()
    },
    submitForm(){
      if(!this.flag){
        return
      }
      this.flag = false

      let _this = this
      _this.$refs['commonForm'].validate((valid) => {
        if (valid) {
          _this.params.fromPage = location.href.split('?')[0]
          _this.params.fromType = this.dialogTypeList[this.dialogType]
          _this.$api.addNewWebsiteData(_this.params).then(res=>{
            if(res?.code){
              // _this.$message.error(res.msg)
              _this.flag= true
            }else{
              _this.$message.success('提交成功~')
              _this.flag= true
              _this.$emit('change', false)
              _this.$refs['commonForm'].resetFields()
            }
          })
        } else {
          _this.flag= true
          return false;
        }
      })
    }
  }
}
