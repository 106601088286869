
import VerificationCode from '@/components/business/verification_code.vue'
export default {
  components: {
    VerificationCode
  },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: {
      type: Boolean,
      default: () => false
    },
    dialogType: {
      type: Number,
      default: () => 0
    }
  },
  data() {
    const validatePhone =  (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请填写手机号'));
      } else if (!/^(0|86|17951)?(13[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|14[0-9]|19[0-9])[0-9]{8}$/.test(value)) {
        callback(new Error('请填写正确的手机号码'));
      } else {
        callback();
      }
    }
    return {
      info: {
        companyName: '',
        name: '',
        phone: '',
        code: '',
        popularizeStoreName: '',
        popularizeRegion: '',
        popularizeEcType: '',
        popularizeFocus: '',
        time: 0,
      },
      formRules:{
        name: [{ required: true, message: '请填写姓名', trigger: 'submit' }],
        companyName: [{ required: true, message: '请填写企业名称', trigger: 'submit' }],
        phone: [{ required: true, message: '请填写手机号', trigger: 'blur' }, { validator: validatePhone, trigger: 'blur' }],
        code: [{ required: true, message: '请填写验证码', trigger: 'submit' }],
      },
    }
  },
  computed: {
    phonePass () {
      return /^(0|86|17951)?(13[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|14[0-9]|19[0-9])[0-9]{8}$/.test(this.info.phone)
    }
  },
  methods: {
    getCode () {
      if (!this.phonePass) return this.$message.warning('请填写手机号')
      this.$api.sendSms({type:'',popularizePhone: this.info.phone}).then(res=>{
        if(res?.code){
          // this.$message.error(res.msg)
        }else{
          this.$message.success('发送成功~')
          if (this.time) return
          this.info.time = 60
          let timer = setInterval(() => {
            this.$on("hook:beforeDestroy", () => clearInterval(timer))
            this.info.time--;
            if (this.info.time <= 0) {
              clearInterval(timer);
            }
          }, 1000)
        }
      })
    },
    handleBeforeClose () {
      this.$emit('change', false)
      this.$refs['elForm'].resetFields()
    },
    submitTip () {
      this.$refs['elForm'].validate()
    },
    submitForm(){
      this.$refs['elForm'].validate((valid) => {
        if (valid) {
          const params = {
            fromPage: location.href.split('?')[0],
            fromType: '与我们联系',
            popularizePhone: this.info.phone,
            popularizeName: this.info.name,
            popularizeStoreName: this.info.companyName,
            popularizeVerificationCode: this.info.code,
          }
          this.$api.addNewWebsiteData(params).then(res=>{
            if(res?.code){
              // _this.$message.error(res.msg)
            }else{
              this.$store.dispatch('setSubmitSuccessVisible', true)
              this.$emit('change', false)
              this.$refs['elForm'].resetFields()
            }
          })
        }
      })
    }
  }
}
